import axios from "axios";

export default {
  getLog: (params) => {
    return axios.get("/a1/presell/goods/editlog", { params: params });
  },

  moveTestImg:(params)=>{
    return axios.put("/a1/presellConfig/moveTestImg", params);
  },
  deleteTestImg:(params)=>{
    return axios.put("/a1/presellConfig/deleteTestImg", params);
  },
  deleteImg:(params)=>{
    return axios.put("/a1/presellConfig/deleteImg", params);
  },
};