var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"title"},[_vm._v("sku: "+_vm._s(_vm.sku))]),_c('span',{staticStyle:{"margin":"10px"}},[_vm._v("订单列表：")]),_c('div',{staticClass:"content"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.ordertableData,"border":"","max-height":"350"}},[_c('el-table-column',{attrs:{"prop":"id","label":"序列号","width":"60","align":"center"}}),_c('el-table-column',{attrs:{"prop":"order_sn","label":"订单号码\t","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"goods_number","label":"商品总数量","align":"center"}}),_c('el-table-column',{attrs:{"prop":"goods_list","label":"涉及商品条数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"total","label":"总价","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.total)+" ")]}},{key:"header",fn:function(ref){
var column = ref.column;
var $index = ref.$index;
return [_vm._v(" 总价 ")]}}])}),_c('el-table-column',{attrs:{"prop":"hd_id","label":"海鼎ID","width":"90","align":"center"}}),_c('el-table-column',{attrs:{"prop":"buyer","label":"购买者用户名","align":"center"}}),_c('el-table-column',{attrs:{"prop":"buyer","label":"所属国家","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.countryList[_vm.allAdminUserCountry[row.user_id]])+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.lookOrderGoods(row)}}},[_vm._v("查看")])]}}])})],1)],1),(_vm.showOrderInfo.orderInfo.order_sn)?_c('div',[_c('span',{staticStyle:{"margin":"10px"}},[_vm._v("订单商品：")]),_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"padding":"20px"}},[_vm._v(" 订单号："+_vm._s(_vm.showOrderInfo.orderInfo.order_sn)+" ")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.showOrderInfo.tableData,"border":"","max-height":"300"}},[_c('el-table-column',{attrs:{"fixed":"","prop":"id","label":"序号","width":"100","align":"center"}}),_c('el-table-column',{attrs:{"prop":"hd_id","label":"海鼎ID","width":"120","align":"center"}}),_c('el-table-column',{attrs:{"prop":"buyer","label":"用户名","width":"120","align":"center"}}),_c('el-table-column',{attrs:{"prop":"Sku","label":"商品编码","align":"center"}}),_c('el-table-column',{attrs:{"prop":"item_name","label":"商品名称","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"spec","label":"规格","align":"center"}}),_c('el-table-column',{attrs:{"prop":"goods_price","label":"商品单价","align":"center"}}),_c('el-table-column',{attrs:{"prop":"goods_number","label":"商品数量","align":"center"}}),_c('el-table-column',{attrs:{"prop":"goods_total","label":"商品总价","align":"center"}})],1)],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }