<template>
    <div style="width: 40%;">
        
        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">动画开始时间：</p>
          <div class="addAdmin_select">
            <el-date-picker
                v-model="formData.start_time"
                type="datetime"
                placeholder="选择日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                clearable>
            </el-date-picker>
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">动画结束时间：</p>
          <div class="addAdmin_select">
            <el-date-picker
                v-model="formData.end_time"
                type="datetime"
                placeholder="选择日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                clearable>
            </el-date-picker>
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">超过多少天展示成功商品：</p>
          <div class="addAdmin_select">
            <el-input v-model="formData.exceeding_success_days" type="number" placeholder="" clearable />
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">倒计时图片：</p>
          <div class="addAdmin_select">
              <el-upload class="upload-demo"
                ref="uploadImgEl"
                :action="uploadUrl"
                :headers="headerObj"
                :on-preview="handlePreview"
                list-type="picture-card"
                :multiple="false"
                :auto-upload="true"
                :file-list="uploadImgList"
                :on-success="SuccessUploadImg"
                :on-exceed="limitUploadImgNum"
                :limit="uploadImgLimit"
                :before-remove="handleCountDownImgBeforeRemove"
                >
                <i class="el-icon-plus" style="width: 50px; height: 50px;"></i>
              </el-upload>
          </div>
        </div>
        <!--            图片放大预览-->
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <!--  -->
        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">飘移图片：</p>
          <div class="addAdmin_select">
              <el-upload class="upload-demo"
                ref="uploadImgEl"
                :action="uploadUrl"
                :headers="headerObj"
                :on-preview="handlePreview"
                list-type="picture-card"
                :multiple="false"
                :auto-upload="true"
                :file-list="uploadFloatIconImgList"
                :on-success="SuccessUploadFloatIconImg"
                :on-exceed="limitUploadFloatIconImgNum"
                :limit="uploadImgLimit"
                :before-remove="handleFloatIconImgBeforeRemove"
                >
                <i class="el-icon-plus" style="width: 50px; height: 50px;"></i>
              </el-upload>
          </div>
        </div>


        <div class="addAdmin">
          <p class="addAdmin_CharacterSet"></p>
            <div class="addAdmin_select">
                <el-button
                type="primary"
                @click="configmSubmit"
                >修 改</el-button>
            </div>
        </div>
  
      
      
    </div>
  </template>
  
  <script>

  import presellConfigApi from "@/api/admin/presell";

  export default {
    name: "presellConfig",
    data() {
      return {
        formData: {
            start_time: "",
            end_time: "",
            exceeding_success_days:"",
            count_down_img:"", //倒计时图片
            float_icon_img:"", //飘移图片
        },
        uploadUrl: this.$root.DownUrl+'/a1/upload/file', // 图片上传地址
        configData:[],//预售配置
        uploadImgList:[], //上传成功的图片
        uploadFloatIconImgList:[],
        uploadImgLimit: 1, //图片上传限制数量
        acceptImgType:["bmp","jpg","jpeg","png","webp"],//图片格式限制
        // 预览图片
        dialogImageUrl: '',
        dialogVisible: false,
        // 图片上传POST地址
        headerObj: {
          Authorization: JSON.parse(sessionStorage.getItem("token")),
        },
      };
    },
    created() {
        this.getData()
    },
    methods: {
        /* 确认提交修改 */
        configmSubmit(){
            if(confirm('你确定修改预售配置?')){
                this.handleSubmit()
            }
        },
        /* 提交表单 */
        handleSubmit(){
            // 整理配置数据
            let saveData = []
            for (const key in this.configData) {
                let key2 = this.configData[key].config_key
                let temp = {
                    "id":this.configData[key].id,
                    "config_key":key2,
                    "config_value":this.formData[key2]
                }
                saveData.push(temp)
            }

            //修改参数
            this.$axios.put(`/a1/presellConfig/editConfig`, {"all_config":saveData})
            .then((res) => {
                if(res.error==1){
                    this.$message.error(res.message)
                }else{
                    this.$message.success("修改成功");
                    this.getData();
                }			
            })
            .catch((resErr) => {
                this.$message.error("修改失败："+resErr.response.data.message);
            });
        },

        /* 获取所有的配置数据 */
        getData(){
            this.$axios.get("/a1/presellConfig/getConfig").then((res) => {
                let data = res.data.data
                this.configData = data
                this.uploadImgList = []
                this.uploadFloatIconImgList = []
                // 设置值
                for (const key in data) {
                    let key2 = data[key].config_key
                    this.formData[key2] = data[key].config_value
                    // 设置展示图片数据
                    if (key2 ==="count_down_img" && data[key].config_value!=""){
                      const ImgNameArr = data[key].config_value.split('/')
                      this.uploadImgList.push({
                          name: ImgNameArr[ImgNameArr.length - 1],
                          url: data[key].config_value
                      })
                    }
                    // 
                    if (key2 ==="float_icon_img" && data[key].config_value!=""){
                      const ImgNameArr = data[key].config_value.split('/')
                      this.uploadFloatIconImgList.push({
                          name: ImgNameArr[ImgNameArr.length - 1],
                          url: data[key].config_value
                      })
                    }
                }

               
            });
        },
    
              // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓图片相关操作↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓/
              
          // 预览图片
          handlePreview(file) {
              this.dialogImageUrl=file.url;
              this.dialogVisible=true;
          },

          // 上传图片成功回调
          SuccessUploadImg(response, file, fileList) {
            for (let key = 0; key < fileList.length; key++) {
              if (fileList[key].response.error === 0) {
                this.formData.count_down_img = fileList[key].response.data
                this.uploadImgList.push({
                  name: fileList[key].name,
                  url: fileList[key].response.data
                })
              }
            }
          },

          // 上传图片成功回调
          SuccessUploadFloatIconImg(response, file, fileList) {
            for (let key = 0; key < fileList.length; key++) {
              if (fileList[key].response.error === 0) {
                this.formData.float_icon_img = fileList[key].response.data
                this.uploadFloatIconImgList.push({
                  name: fileList[key].name,
                  url: fileList[key].response.data
                })
              }
            }
          },

          // 图片上传超出限制数量
          limitUploadImgNum() {
            if (this.uploadImgList.length >= this.uploadImgLimit) {
              this.$message.error('上传超过数量，请先移除图片！')
            }
          },

          // 图片上传超出限制数量
          limitUploadFloatIconImgNum() {
            if (this.uploadFloatIconImgList.length >= this.uploadImgLimit) {
              this.$message.error('上传超过数量，请先移除图片！')
            }
          },

          /**
          * 删除图片
          */
          handleDeleteImg(imgArr) {
            presellConfigApi.deleteImg({ img_arr: imgArr }).then((res) => {
              const { error } = res.data
              if (error === 0) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              } else {
                this.$message({
                  type: 'info',
                  message: '取消删除'
                })
              }
            })
          },

          /* 删除未提交表单的图片 */
          handleDeleteTestImg(imgArr) {
            presellConfigApi.deleteTestImg({ img_arr: imgArr }).then((res) => {
              const { error } = res.data
              if (error === 0) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              } else {
                this.$message({
                  type: 'info',
                  message: '取消删除'
                })
              }
            })
          },

          /**
          * 删除图片之前确认
          */
          handleCountDownImgBeforeRemove(file, fileList) {
            const bv = false // 阻止直接移除，采用手动移除
            var delArr = []
            if (file.url.indexOf('/uploads/test/') > -1) {
              for (let index = 0; index < this.uploadImgList.length; index++) {
                if (this.uploadImgList[index].url === file.url) {
                  this.uploadImgList.splice(index, 1) // 移除展示的图片
                  delArr.push({ imgSrc: file.url })
                }
              }
              if (delArr.length > 0) {
                this.handleDeleteTestImg(delArr) // 删除test路径的图片
              }
            } else {
              this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })
                .then((res) => {
                  // 移除展示的数组
                  for (let index = 0; index < this.uploadImgList.length; index++) {
                    if (this.uploadImgList[index].url === file.url) {
                      this.uploadImgList.splice(index, 1) // 移除展示的图片
                    }
                  }
                  // 修改数据
                  delArr.push({ imgSrc: this.formData.count_down_img })
                  this.handleDeleteImg(delArr)
                  // 移除表单的数据
                  this.formData.count_down_img = ''
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '取消删除'
                  })
                })
            }
            // 阻止删除
            return bv
          },

                   /**
          * 删除图片之前确认
          */
          handleFloatIconImgBeforeRemove(file, fileList) {
            const bv = false // 阻止直接移除，采用手动移除
            var delArr = []
            if (file.url.indexOf('/uploads/test/') > -1) {
              for (let index = 0; index < this.uploadFloatIconImgList.length; index++) {
                if (this.uploadFloatIconImgList[index].url === file.url) {
                  this.uploadFloatIconImgList.splice(index, 1) // 移除展示的图片
                  delArr.push({ imgSrc: file.url })
                }
              }
              if (delArr.length > 0) {
                this.handleDeleteTestImg(delArr) // 删除test路径的图片
              }
            } else {
              this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })
                .then((res) => {
                  // 移除展示的数组
                  for (let index = 0; index < this.uploadFloatIconImgList.length; index++) {
                    if (this.uploadFloatIconImgList[index].url === file.url) {
                      this.uploadFloatIconImgList.splice(index, 1) // 移除展示的图片
                    }
                  }
                  // 修改数据
                  delArr.push({ imgSrc: this.formData.float_icon_img })
                  this.handleDeleteImg(delArr)
                  // 移除表单的数据
                  this.formData.float_icon_img = ''
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '取消删除'
                  })
                })
            }
            // 阻止删除
            return bv
          }
          // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑图片相关操作↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑/
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .addAdmin {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;

  &_CharacterSet {
    font-size: 15px;
    width: 200px;
    text-align: right;
  }

  &_select {
    flex: 1;
    justify-content: flex-start;
  }
  &_swith {
    text-align: center;
  }
}

::v-deep{
    .addAdmin_select > .el-input{
        width: 220px;
        padding-right: 0;
    }
}

  </style>
  