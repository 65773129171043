<template>
    <div>
        <div style="padding-left: 10px; margin-top: 10px">
			<el-button @click="addRowShow()" type="danger" size="mini">添加</el-button>
		</div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="ID" width="180">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="分类名称" width="180" >
          <template slot-scope="scope">
            <span v-if="scope.row.isRepeat == true" style="color: red;" >{{ scope.row.name }}</span>
            <span v-else>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="en_name" label="分类名称(EN)" width="180">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
  
      <!-- 活动管理 -->
    <el-dialog :title="typeTitle" :visible.sync="dialogVisible" width="30%">
        <div>
            <el-form ref="form" label-width="80px">
                <el-form-item label="名称(CN)">
                    <el-input v-model="saveData.name"></el-input>
                </el-form-item>

                <el-form-item label="名称(EN)">
                    <el-input v-model="saveData.en_name"></el-input>
                </el-form-item>

                <el-form-item label="是否显示">
                    <el-switch v-model="saveData.is_show" :active-value="1" active-text="是" :inactive-value="0" inactive-text="否">
                    </el-switch>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitFormData">提 交</el-button>
        </span>
    </el-dialog>
  
      <div>
        <el-pagination
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :total="pages.total">
        </el-pagination>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "presellType",
    data() {
      return {
        formId:"",
        saveData: {
          is_show: "",
          name: "",
          en_name:"",
        },

        dialogVisible: false,
        tableData: [],
        pages: {
          page: 1,
          page_size: 10,
          total: 50,
        },
        submitType:"add",
        typeTitle:"添加分类"
      };
    },
    created() {
      this.getList();
    },
    methods: {
        addRowShow() {
				this.dialogVisible = true;
        this.submitType = "add"
        },

        //提交Ip活动
			submitFormData() {
				let submitType = this.submitType;
				let saveData = {};
        saveData.id = this.saveData.id
				saveData.name = this.saveData.name;
				saveData.en_name = this.saveData.en_name;
        saveData.is_show = parseInt(this.saveData.is_show)
				
				if (submitType == "add") {
					this.addPresellType(saveData);
				}
				if (submitType == "update") {
					this.putPresellType(saveData);
				}
			},
            //添加
			addPresellType(psotData) {
				//添加IP活动
				this.$axios
					.post(`/a1/presell/addType`, psotData)
					.then((res) => {
						if (res.error == 1) {
              this.$message.error(res.message)
            }else{
              this.$message.success("添加成功");
						  this.getList();
						  this.dialogVisible = false;
            }
					})
					.catch((resErr) => {
						this.$message.error("添加失败："+resErr.response.data.message);
					});
			},

      //修改
			putPresellType(putData) {
				if (this.formId > 0) {
					let formId = this.formId
					//修改参数
					this.$axios
						.put(`/a1/presell/editType/${formId}`, putData)
						.then((res) => {
              if(res.error==1){
                this.$message.error(res.message)
              }else{
                this.$message.success("修改成功");
							  this.getList();
						    this.dialogVisible = false;
              }
							
						})
						.catch((resErr) => {
							this.$message.error("修改失败："+resErr.response.data.message);
						});
				}
			},
      //当前页变化
      handleCurrentChange(val) {
        //console.log(`当前页: ${val}`);
        this.pages.page = val;
        this.getList();
      },


      //编辑
      handleEdit(index, row){
        this.formId = row.id
        this.submitType = "update"
        this.dialogVisible = true
        this.saveData.id = this.formId
        this.saveData.is_show = parseInt(row.is_show)
        this.saveData.name=row.name
        this.saveData.en_name= row.en_name
      },

          //删除绑定列表
    handleDelete(index, row) {
      if (confirm("确认删除吗？")) {
        this.$axios.delete(`/a1/presell/delType/` + row.id).then((res) => {
          this.$message.success("删除成功");
          this.getList();
        }).catch((err) => {
          this.$message.error("删除失败："+err.response.data.message);
        });
      }
    },
    
      //获取到绑定列表
      getList() {
        this.$axios.get("/a1/presell/preType", {
          params: {
            page: this.pages.page,
            page_size: this.pages.page_size,
          },
        }).then((res) => {
          this.tableData = res.data.data;
          for (let index = 0; index < this.tableData.length; index++) {
            this.tableData[index].isRepeat = false
          }
          this.pages.total = res.data.data.page_size;
          // 判断是否有重复值
          this.checkRepeat()
          // console.log(this.tableData)
        });
      },

      // 高亮重复值
      checkRepeat(){
        var tableDataArr = []
        for (const key in this.tableData) {
          tableDataArr.push(this.tableData[key].name)
        }
        var dataString  = ","+tableDataArr.join(",")+","
        for (let index = 0; index < this.tableData.length; index++) {
          if (dataString.replace(","+this.tableData[index].name+",","").indexOf(","+this.tableData[index].name+",")>-1) {
            // console.log(this.tableData[index].name)
             this.tableData[index].isRepeat = true
          } else{
             this.tableData[index].isRepeat = false
          }
        }
      }
    
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  