<template>
	<div>
		<div class="title">sku: {{sku}}</div>
		<span style="margin: 10px;">订单列表：</span>
		<div class="content">
			<el-table :data="ordertableData" style="width: 100%;" border max-height="350">
				<el-table-column prop="id" label="序列号" width="60" align="center">
				</el-table-column>
				<el-table-column prop="order_sn" label="订单号码	" width="180" align="center">
				</el-table-column>
				<el-table-column prop="goods_number" label="商品总数量" align="center">
				</el-table-column>
				<el-table-column prop="goods_list" label="涉及商品条数" align="center">
				</el-table-column>
				<el-table-column prop="total" label="总价" width="120" align="center">
					<template slot-scope="{row}">
						{{row.total}}
					</template>
					<template slot="header" slot-scope="{ column, $index }">
						总价
						<!-- <p style="color: red;">{{allOrderTotal}}</p> -->
					</template>
				</el-table-column>

				<el-table-column prop="hd_id" label="海鼎ID" width="90" align="center">
				</el-table-column>
				<el-table-column prop="buyer" label="购买者用户名" align="center">
				</el-table-column>
				
				<!--<el-table-column prop="order_status" label="订单状态" width="120" align="center">
					<template slot-scope="{row}">
						<span style="color: red;" v-if="row.order_status > 0">{{orderStatus[row.order_status]}}</span>
						<span v-else>{{orderStatus[row.order_status]}}</span>
					</template>
				</el-table-column>-->

				<el-table-column prop="buyer" label="所属国家" align="center">
					<template slot-scope="{row}">
						{{countryList[allAdminUserCountry[row.user_id]]}}
					</template>
				</el-table-column>

				<!-- <el-table-column label="审核" width="150" align="center">
					<template slot-scope="{row}">
						<div v-if="row.order_status == 0">
							<div>
								<el-button size="mini" type="primary" @click="settingData(row,1)">通过</el-button>
								<el-button size="mini" type="danger" @click="settingData(row,2)">拒绝</el-button>
							</div>
							<p>审核备注：</p>
							<el-input type="text" v-model="row.reason"></el-input>
						</div>
						<div v-else>
							备注：{{row.reason}}
						</div>
					</template>
				</el-table-column> -->

				<el-table-column label="操作" align="center">
					<template slot-scope="{row}">
						<el-button size="mini" @click="lookOrderGoods(row)">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div v-if="showOrderInfo.orderInfo.order_sn">
			<span style="margin: 10px;">订单商品：</span>
			<div class="content">
				<div style="padding: 20px;">
					订单号：{{showOrderInfo.orderInfo.order_sn}}
				</div>
				<el-table :data="showOrderInfo.tableData" border style="width: 100%" max-height="300">
					<el-table-column fixed prop="id" label="序号" width="100" align="center">
					</el-table-column>
					<el-table-column prop="hd_id" label="海鼎ID" width="120" align="center">
					</el-table-column>
					<el-table-column prop="buyer" label="用户名" width="120" align="center">
					</el-table-column>
					<el-table-column prop="Sku" label="商品编码" align="center">
					</el-table-column>
					<el-table-column prop="item_name" label="商品名称" width="180" align="center">
					</el-table-column>
					<el-table-column prop="spec" label="规格" align="center">
					</el-table-column>
					<el-table-column prop="goods_price" label="商品单价" align="center">
					</el-table-column>
					<el-table-column prop="goods_number" label="商品数量" align="center">
					</el-table-column>
					<el-table-column prop="goods_total" label="商品总价" align="center">
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'trainCity',
		props: {
			sku: {
				type: String,
				default: "请输入你的参数"
			},
		}, // 用来接收父组件传给子组件的数据
		data() {
			return {
				orderStatus: {
					"0": "待审核",
					"1": "审核通过",
					"2": "审核不通过",
				},
				ordertableData: [],
				goodstableData: [],
				showOrderInfo: {
					orderInfo: {},
					tableData: []
				},
				//allOrderTotal: 0,
				countryList: [], //所有国家列表
				allAdminUserCountry: [] //获取用户对应国家信息
			}
		},
		created() {
			this.queryOrder();
			this.initInfoData();
			this.getCountry();
			this.getAllUserCountry();
		},
		watch: {
			"sku"() {
				this.queryOrder();
				this.initInfoData();
			}
		},
		methods: {
			//获取用户对应国家信息
			getAllUserCountry() {
				this.$axios.get(`/a1/user/all`).then((res) => {
					let resData = res.data.data;
					this.allAdminUserCountry = []
					for (let key in resData) {
						this.allAdminUserCountry[resData[key]['id']] = resData[key]['country'];
					}
				})
			},
			//获取到所有国家
			getCountry() {
				this.$axios.get(`/a1/country`).then((res) => {
					let resData = res.data.data;
					this.countryList = [];
					for (let key in resData) {
						this.countryList[resData[key]['code']] = resData[key]['name'];
					}
				});
			},
			//初始化订单商品信息
			initInfoData() {
				this.showOrderInfo.orderInfo = {}
				this.showOrderInfo.tableData = []
			},
			//查询订单商品
			lookOrderGoods(row) {
				this.showOrderInfo.orderInfo = row
				this.$axios.get(`/a1/presell/getOrderGoods/${row.order_sn}`).then((res) => {
					let resData = res.data;
					this.showOrderInfo.tableData = resData.data
				})
			},
			//设置
			settingData(row, key) {
				let updateData = {
					"status": key,
					"order_id": row.id,
					"reason": row.reason,
				}
				this.$axios.put("/a1/presell/setOrder/Status", updateData).then((res) => {
					this.queryOrder();
				});
			},
			//查询订单
			queryOrder() {
				let sku = this.sku;
				this.$axios.get(`/a1/presell/skuFindOrder/${sku}`).then((res) => {
					let resData = res.data;
					this.ordertableData = resData.data;
					// let allOrderTatal = 0;
					// for (let key in resData.data) {
					// 	allOrderTatal += parseFloat(resData.data[key].total)
					// }
					// this.allOrderTotal = allOrderTatal.toFixed(2);
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.title {
		background: rgb(33, 141, 160);
		padding: 12px;
		color: white;
	}

	.content {
		border: 1px solid rgb(33, 141, 160);
		padding: 10px;
	}
</style>
