<template>
  <div>
    <div>
      标签：
      <el-select v-model="saveData.label" placeholder="预售月份" size="mini" clearable>
        <el-option v-for="item in labelArr" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      &nbsp;&nbsp;&nbsp;
      名称：
      <el-input style="width: 250px;" v-model="saveData.name"></el-input>
      <el-button @click="addBind">添加</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="标签" width="180">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.label }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="绑定名称" width="180">

      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="绑定sku" :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
      <div>
        <div style="border-bottom: 2px solid #80b8d4; padding: 5px;">
          预售编码:
          <el-input style="width: 250px;" v-model="bindCode"></el-input>
          &nbsp;
          <el-button @click="addSku">添加</el-button>
        </div>
        <table>
          <el-table :data="tableDataList">
            <el-table-column prop="sku" label="预售编码" width="180">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="removeSku(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </table>
      </div>


      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <div>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="pages.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "bandcode",
  data() {
    return {
      labelArr: [
        {
          value: "2022-09",
          label: "2022-09",
        },
        {
          value: "2022-08",
          label: "2022-08",
        },
        {
          value: "2022-07",
          label: "2022-07",
        },
        {
          value: "2022-06",
          label: "2022-06",
        },
        {
          value: "2022-05",
          label: "2022-05",
        },
        {
          value: "2022-04",
          label: "2022-04",
        }, {
          value: "2022-03",
          label: "2022-03",
        },
      ],
      saveData: {
        label: "",
        name: "",
      },
      tableData: [],
      dialogVisible: false,
      tableDataList: [],
      showBid: 0, //显示的绑定ID
      bindCode: "", //添加的绑定sku
      pages: {
        page: 1,
        page_size: 10,
        total: 50,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    //关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {
        });
    },
    //当前页变化
    handleCurrentChange(val) {
      //console.log(`当前页: ${val}`);
      this.pages.page = val;
      this.getList();
    },
    //添加绑定
    addBind() {
      this.$axios.post(`/a1/presell/bind`, {
        "name": this.saveData.name,
        "label": this.saveData.label,
      }).then((res) => {
        this.$message.success("添加成功");
        this.getList();
        this.saveData = {
          label: "",
          name: "",
        };
      }).catch((_) => {
        this.$message.error("添加失败");
      });
    },
    //获取到绑定列表
    getList() {
      this.$axios.get("/a1/presell/bind", {
        params: {
          page: this.pages.page,
          page_size: this.pages.page_size,
        },
      }).then((res) => {
        this.tableData = res.data.data.data;
        this.pages.total = res.data.data.page_size;
      });
    },
    //编辑预售SKU
    handleEdit(index, row) {
      this.dialogVisible = true;
      this.bindCode = "";
      this.findSkuByBid(row.id);
    },
    //删除绑定列表
    handleDelete(index, row) {
      if (confirm("确认删除吗？")) {
        this.$axios.delete(`/a1/presell/bind/` + row.id).then((res) => {
          this.$message.success("删除成功");
          this.getList();
        }).catch((_) => {
          this.$message.error("删除失败");
        });
      }
    },
    //查找该编码的列表
    findSkuByBid(id) {
      this.showBid = id;
      this.$axios.get("/a1/presell/bind/sku/list/" + id).then((res) => {
        this.tableDataList = res.data.data;
      });
    },
    //删除预售商品
    removeSku(row) {
      //删除sku绑定
      this.$axios.delete(`/a1/presell/bind/rk/` + row.id).then((res) => {
        this.$message.success("删除成功");
        this.findSkuByBid(this.showBid);
      }).catch((_) => {
        this.$message.error("删除失败");
      });
    },
    //提交绑定
    addSku() {
      //添加绑定
      this.$axios.post(`/a1/presell/bind/sku`, {
        "pb_id": this.showBid,
        "sku": this.bindCode,
      }).then((res) => {
        this.$message.success("添加成功");
        this.findSkuByBid(this.showBid);
      }).catch((_) => {
        this.$message.error("提交失败");
      });
    },
  },
};
</script>

<style scoped>

</style>
